var host = location.protocol + '//' + location.hostname;    //请求域名


// host = "https://testedu.fucity.cn";   //测试地址
// host = "https://ypt.fucity.cn";       //线上地址 


// host = "https://cuypt.fucity.cn";       //高校版线上地址

// host ="https://generic.fucity.cn";         //通用版线上地址

// host = 'https://xlcpapi.cd120.com.cn/'; //常德医院地址

// host = 'http://192.168.1.13';       //康哥的地址 

// host = 'http://192.168.1.24';     //江哥
host = "https://hsypt.fucity.cn";
// var imgHost = "http://xcx.fucity.cn/";   
var config = {
    host
    // imgHost
}
export default config; //输出配置 